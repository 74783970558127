<template>
    <div class="container repair-container" v-if="device">

        <!-- Links -->
        <label>What type of image are you adding?</label>
        <div class="card-grid card-grid-scroll mb-0">
            <div class="card-link-container">
                <input type="file" name="frontPhoto" id="serialNumber" class="file-input" accept="image/*" capture="environment" @change="addPhoto(`serial-number-label`, 'serialNumber')">
                <label class="card card-body card-body-sm card-link vertical bg-gradient-info pb-2" for="serialNumber">
                    <icon-serial-number
                            size="md"
                            class="icon-all-white mb-2">
                    </icon-serial-number>
                    Serial Number <small>(required)</small>
                </label>
            </div>
            <div class="card-link-container">
                <input type="file" name="frontPhoto" id="proofOfPurchase" class="file-input" accept="image/*" capture="environment" @change="addPhoto(`proof-of-purchase`, 'proofOfPurchase')">
                <label class="card card-body card-body-sm card-link vertical bg-gradient-info pb-2" for="proofOfPurchase">
                    <icon-invoice
                            size="md"
                            class="icon-all-white mb-2">
                    </icon-invoice>
                    Proof of Purchase <small>(optional)</small>
                </label>
            </div>
        </div>

        <!-- Serial Number Required Banner -->
        <div class="alert alert-warning" v-if="showSerialBanner">
            <animated-warning></animated-warning>
            <div class="alert-content">
                Please supply an image of the serial number
            </div>
            <button class="btn btn-light btn-sm" @click="openCantAddSerialModal">Can't add serial</button>
        </div>

        <!-- Empty state -->
        <div class="card card-body" v-if="showEmptyState">
            <empty-state
                    emptyIcon
                    icon="camera"
                    title="No images uploaded"
                    subtitle="Select one of the options above to add an image">
            </empty-state>
        </div>

        <!-- List of photos -->
        <template>
            <div class="card-grid grid-col-2 grid-col-md-3">
                <div class="uploaded-photo-item" v-if="device.sn_supplied">
                    <div class="img-container bg-secondary d-flex">
                        <icon-serial-number class="m-auto"></icon-serial-number>
                    </div>
                    <small class="title">Serial number uploaded</small>
                </div>
                <div class="uploaded-photo-item" v-if="!device.sn_supplied && device.no_serial_supplied === true">
                    <div class="img-container bg-secondary d-flex">
                        <icon-serial-number class="m-auto empty-icon"></icon-serial-number>
                    </div>
                    <small class="title">Serial can't be added</small>
                </div>
                <div class="uploaded-photo-item" v-if="device.pop_supplied">
                    <div class="img-container bg-secondary d-flex">
                        <icon-invoice class="m-auto"></icon-invoice>
                    </div>
                    <small class="title">Proof of purchase uploaded</small>
                </div>
            </div>

        </template>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': footerActive}"
                :nextLabel="this.nextRepairStep($route.name).title"
                :nextRoute="this.nextRepairStep($route.name).routeName"
                :prevRoute="this.prevRepairStep($route.name).routeName">
        </fixed-footer>

        <!-- Can't scan serial number modal -->
        <cant-add-serial-modal
                :device-id="deviceId"
                :job-id="jobId">
        </cant-add-serial-modal>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Compressor from 'compressorjs';

    export default {
        props: ['jobId', 'deviceId'],

        data() {
            return {
                imagePaths:[],
            }
        },

        mounted() {
            this.loadDeviceForJob({
                jobId: this.jobId,
                deviceId: this.deviceId,
            }).then(() => {
                this.setCurrentStep({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    currentStep: this.$route.name,
                });
            });
        },

        computed: {
            ...mapGetters([
                "device",
                "isJobManager",
                "nextRepairStep",
                "prevRepairStep"
            ]),

            /** Check if footer should be active */
            footerActive() {
                if (this.device && (this.device.sn_supplied || this.device.no_serial_supplied || this.isJobManager)) {
                    return true;
                }

                return false;
            },

            /** Show the empty state */
            showEmptyState() {
                if (this.device && (!this.device.sn_supplied && !this.device.pop_supplied && (!this.device.no_serial_supplied || this.device.no_serial_supplied === false))) {
                    return true;
                }

                return false;
            },

            /** Show the serial number required banner */
            showSerialBanner() {
                if (this.device && (!this.device.sn_supplied && (!this.device.no_serial_supplied || this.device.no_serial_supplied === false))) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            ...mapActions([
                "addDeviceImage",
                "displayToast",
                "loadDeviceForJob",
                "setCurrentStep",
                "uploadWarrantyValidationImage"
            ]),

            /** Add a new photo */
            addPhoto(type, elementId) {
                const filePicker = document.getElementById(elementId);

                if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
                    return;
                }

                let self = this;
                new Compressor(filePicker.files[0], {
                    quality: 0.6,
                    success(result) {

                        self.displayToast({text: 'File is being uploaded.', type: 'success'});

                        let formData = new FormData();
                        formData.append("type", type);
                        formData.append("file", result, result.name);

                        self.uploadWarrantyValidationImage({
                            deviceId: self.deviceId,
                            formData: formData
                        });

                        filePicker.value = "";
                    },
                    error() {
                        self.displayToast({text: 'Could not parse file.', type: 'error'});
                        filePicker.value = "";
                    },
                });
            },

            /** Open can't add serial number modal */
            openCantAddSerialModal() {
                this.modalOpen = true;
                this.$bvModal.show('cant-add-serial-modal');
            },
        }
    }
</script>

<style scoped>

</style>